@import '../../styles/app';

.root {
    @media print{
        .row{
            display: block;
        }
        .page-break {
            page-break-after: always;
        }
    
    }
}