$theme-colors: (
  primary: $blue,
  secondary: $gray-600,
  success: $teal,
  info: $purple,
  warning: $yellow,
  danger: $red,
  dark: $gray-800,
  inverse: $gray-700,
  gray: $gray-300,
  light: $gray-100,
  default: #e9ecef,
  primary-light: #dee4ee,
  success-light: #ecfaec,
  info-light: #f2fafa,
  warning-light: #fdf7e6,
  danger-light: #fff2ef,
);

$widget-border: 1px solid #999;
$widget-shadow: 0 0.5rem 1rem rgba($black, 0.15) !default;

$link-color: #218bc3;

$text-color: $gray-700 !default;

// Bootstrap Grid
// -------------------------
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1700px,
  xxl: 2200px
);

//MISSING PROPS
// Sidebar
$sidebar-width-open: 224px;
$sidebar-width-closed: 50px;
$navbar-bg: $gray-900 !default;
$navbar-shadow: none;
$logo-color: $gray-100;