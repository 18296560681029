
  button.header-link {
    border: 0;
    background-color: transparent !important;
    color: inherit !important;
    font-weight: 600;
    text-decoration: underline;
    padding: 0 !important;
    @media print {
      background-color: none;
      text-decoration: none;
    }
  }
  
  a.header-link {
    border: 0;
    background-color: transparent !important;
    color: inherit !important;
    font-weight: 600;
    text-decoration: underline;
    padding: 0 !important;
    @media print {
      background-color: none;
      text-decoration: none;
    }
  }
  
  a.more-link {
    border: 0;
    background-color: transparent !important;
    color: #3a42b3 !important;
    font-weight: 300;
    font-size: 12px;
    text-decoration: none;
    padding: 0 !important;
    @media print {
      background-color: none;
      text-decoration: none;
    }
  }
  
  .img-poster {
    min-height: 50px !important;
    height: 100%;
  }
  
  
  @media print{
    @page {
      size: landscape;
    }
    u {
      text-decoration: none !important;
    }
    body {
      overflow: hidden !important;
      height: auto;
    }
    .noprint {
      visibility: hidden !important;
   }
  }

  .menuContainer {
    width: 100%;
    height: 60px;
    background-color: #fff;
    box-shadow: -1px 0 8px rgba(0,0,0,.5);
    padding: 15px 20px 15px 20px;
    
    @media print {
      display:none;
    }
  }
  
  .menuContainer.lg {
    height: 150px !important;
  }
  
  .menuTitle {
    position: relative;
    z-index: 2;
  }

  .recharts-label-list {
      font-size:11px !important;
  }




  //REACT-SLICK
  .slick-slide {
    padding:0 4px;
    margin-bottom: 5px;
  }
  .slick-list {
    margin: 0 2em;
  }
  .slick-prev {
    border: 1px solid #F5F5F5;
    border-radius: 4px;
    width: 48px;
    height: 48px;
    font-size: 44px;
    color: #000;
    margin-right:10px;
  }
  .slick-prev:before {
      content: "\f137";
      display: inline-block;
      font-family: "Font Awesome 6 Pro";
      font-weight: 900;
      font-size: 44px;
      color: #000;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      transform: translate(0, 0);
  }
  .slick-next {
    border: 1px solid #F5F5F5;
    border-radius: 4px;
    width: 48px;
    height: 48px;
    color: #000;
    margin-left:10px;
  }
  .slick-next:before {
      content: "\f138";
      display: inline-block;
      font-family: "Font Awesome 6 Pro";
      font-weight: 900;
      font-size: 44px;
      color: #000;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      transform: translate(0, 0);
  }
  .slick-slide .card {
    background-color: #fafafa !important;
  }



  .bigStatTitle {
    width: 220px;
    margin-right: 0.5rem;
  }
  
  .arrowAlign {
    margin-top: -5px;
  }
  
  .page-divide {
    page-break-after: always !important;
    page-break-before: always !important;
  }
  
  table{word-break: break-word;}
  tr{page-break-inside: avoid !important;}
  thead {
    display: table-row-group !important;
  }