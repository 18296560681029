@import '../../styles/app';

.shows {
  
  @include media-breakpoint-up(xs) {
    width: $sidebar-width-open * 1.6;
  }

  @include media-breakpoint-up(sm) {
    width: $sidebar-width-open * 1.75;
  }

  @include media-breakpoint-up(md) {
    width: 500px;
  }

  @include media-breakpoint-up(lg) {
    width: 650px;
  }

  @include media-breakpoint-up(lg) {
    width: 750px;
  }

  height: 100%;
  border: none;
}

.cardHeader {
  border-radius: 0;
}

.cardFooter {
  padding-top: 14px;
  padding-bottom: 14px;
  border-top: none;
}
