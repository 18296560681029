@import '../../styles/app';

.menu {
  
  @include media-breakpoint-down(md) {
    width: 100% !important;
  }

  @include media-breakpoint-up(md) {
    width: $sidebar-width-open * 1.75;
  }

  // @include media-breakpoint-up(lg) {
  //   width: 500px;
  // }

  height: 100%;
  border: none;

  z-index:999999 !important;
}

.cardHeader {
  border-radius: 0;
}

.cardFooter {
  padding-top: 14px;
  padding-bottom: 14px;
  border-top: none;
}

.btnNotificationsReload {
  color: $navbar-link-color;
  outline: none;

  i::before {
    top: 2px;
  }
}
