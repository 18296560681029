@import '../../styles/app';

.root {
  z-index: 100;
  background: #444;
  box-shadow: $navbar-shadow;
  padding: 7px 10px;

  @include media-breakpoint-down(sm) {
    padding: 7px 0;
  }

  :global {
    .input-group {
      overflow: hidden;
    }

    .input-group-no-border {
      margin-top:6px;
      border-top-left-radius: 0.3rem;
      border-bottom-left-radius: 0.3rem;
    }

    .input-group-prepend {
      background-color: #fff;
      transition: background-color ease-in-out 0.15s;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
    }

    .input-group-text{
      background-color: #fff;
      border: 0;
    }

    .focus .input-group-prepend {
      background: #f8f9fa;
    }
    
  }
}

.logo {
  margin-top: 2px;
}

.navbarForm {
  padding: 6px 0 6px 1rem;
  margin-left: 10px;
  display: inline-block;
  top: 2px;
  width: auto;

  .inputAddon {
    position: relative;
    display: inline;
    border: none;
    background-color: #fff;
    transition: background-color ease-in-out 0.15s;
  }

  input {
    border: none;
    padding: 0.6rem 0.85rem 0.6rem 0;
    display: inline !important;
    width: 250px !important;
    top: 2px;
  }
}
.navbarBrand {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  pointer-events: none;
  color: $logo-color !important;

  i {
    font-size: 10px;
  }
}

.notificationsMenu {
  :global .dropdown-menu {
    left: 0 !important;
    right: auto !important;
    top: 0 !important;

    @include media-breakpoint-down(md) {
      position: fixed !important;
      left: 0 !important;
    }

  }
}

.notificationsWrapper {
  width: min-content;

  @include media-breakpoint-down(md) {
    width: 100% !important;
  }
}

.mainMenu {
  :global .dropdown-menu {
    left: auto !important;
    right: 0 !important;
    top: 0 !important;

    // @include media-breakpoint-down(lg) {
    //   position: fixed !important;
    //   left: 0 !important;
    // }
    
  }
}

.mainMenuWrapper {
  width: min-content;

  @include media-breakpoint-down(md) {
    width: 100% !important;
  }
}

.showsMenu {
  :global .dropdown-menu {
    left: 44px !important;
    right: auto !important;
    top: 0 !important;
  }
}

.showsWrapper {
  width: min-content;
}
