@import '../../styles/app';

@media print {
  .root {
    height: 100%;
    position: relative;
    left: 0;
    transition: left $sidebar-transition-time ease-in-out;
    -webkit-print-color-adjust: exact;

    &.chatOpen {
      left: -($sidebar-width-open + $sidebar-width-closed);
    }
  }
}

.wrap {
  position: relative;
  min-height: 100vh;
  display: flex;
  margin-left: 0px;
  flex-direction: column;
  left: 0;
  right: 0;
  transition: left $sidebar-transition-time ease-in-out;

  @media (max-width: breakpoint-max(sm)) {
    margin-left: 0;
    left: 0;
  }

  @media print {
    margin-left: 0;
    left: 0;
  }
}

.sidebarClose div.wrap {
  left: 0;
}

.sidebarStatic .wrap {
  transition: none;
  left: 0;
  margin-left: 0;
}

.content {
  position: relative;
  flex-grow: 1;
  // 20px for footer height
  //padding: $content-padding $content-padding ($content-padding + 20px);
  padding: 0px;
  background-color: $body-bg;

  @media (max-width: breakpoint-max(sm)) {
    //padding: 20px 15px;
    padding: 0px;
  }

  // hammers disallows text selection, allowing it for large screens
  @media (min-width: breakpoint-min(sm)) {
    user-select: auto !important;
  }

  @media print {
    margin-left: 0;
    left: 0;
    background-color: #fff !important;
  }
}

.contentFooter {
  position: absolute;
  bottom: 15px;
  color: $text-muted;
}
