@import '../../styles/app';

.themeHelper {
  width: $helper-width;
  position: fixed;
  right: -$helper-width;
  top: 40px;
  bottom: 20px;
  z-index: 101;

  .helperHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 30px 0;
  }

  @media print {
    display:none;
  }

  @include transition(right $sidebar-transition-time ease-in-out);

  & :global {
    .abc-radio-secondary input[type="radio"]:not(:checked)+label::before {
      background-color: #798892;
    }

    .abc-radio {
      & > label {
        padding-left: 25px;
  
        &:before, &:after {
          outline: none !important;
          transition: $transition-base;
          margin-left: 4px;
        }
      }
    }

    .abc-radio-warning input[type="radio"]:not(:checked)+label::before {
      background-color: theme-color('warning');
    }
  }

  .themeSwitcher {
    display: flex;
    flex-direction: column;
    align-items: center;

    .theme {
      position: relative;

      &,
      & > label {
        width: 100%;
        height: max-content;
      }

      & > input {
        position: absolute;
        width: 0;
        height: 0;
        padding: 0;
        margin: 0;
        pointer-events: none;
        opacity: 0;
      }

      & > label {
        margin: 0;
        border: 1px solid $input-border-color;
        padding: 3px;
        border-radius: $border-radius;
        transition: background-color .2s ease-in-out;
        cursor: pointer;
        display: block;

        &:hover {
          background-color: $gray-200;
        }
      }

      & > input:checked + label {
        background-color: $gray-300;
      }

      .themeImage {
        width: 100%;
      }
    }
  }

  &.themeHelperOpened {
    right: 0;
  }

  .themeHelperBtn {
    position: absolute;
    top: 7%;
    right: unset;
    margin-left: 20px;
    width: $sidebar-width-open / 4;
    transform: translateX(-76px);
    margin-top: -($widget-padding-vertical);
    cursor: pointer;
    z-index: 0;
    border-radius: 50% 0 0 50%;
    padding: $spacer * 1.2 $spacer / 2 $spacer * 1.2 $spacer;
    &,
    &:not(.active) {
      box-shadow: $widget-shadow-designated !important;
    }
  }

  .themeHelperBtnFiltered {
    position: absolute;
    top: 7%;
    right: unset;
    margin-left: 20px;
    width: $sidebar-width-open / 4;
    transform: translateX(-76px);
    margin-top: -($widget-padding-vertical);
    cursor: pointer;
    z-index: 0;
    border-radius: 50% 0 0 50%;
    padding: $spacer * 1.2 $spacer / 2 $spacer * 1.2 $spacer;
    &,
    &:not(.active) {
      box-shadow: $widget-shadow-designated !important;
    }
  }

  .themeHelperSpinner {
    font-size: $font-size-lg * 1.4;
    line-height: initial;
  }

  .themeHelperHeader {
    padding-top: 0;

    h6 {
      margin: auto;
    }
  }

  .themeHelperContent {
    box-shadow: $widget-shadow-designated;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    overflow-y: scroll;
    height: 100%;

    &::-webkit-scrollbar {
      width: 1em;
    }
    &::-webkit-scrollbar-track {
      box-shadow: none;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #333;
      outline: 1px solid #000;
    }

  }

  .themeHelperSharing {
    font-size: $font-size-lg;
    cursor: pointer;
  }

  :global .glyphicon {
    vertical-align: top;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    50% {
      transform: rotate(360deg);
    }
  }

  @keyframes spin-reverse {
    0% {
      transform: rotate(0deg);
    }

    50% {
      transform: rotate(-360deg);
    }
  }
}
